exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-design-agency-js": () => import("./../../../src/pages/ai-design-agency.js" /* webpackChunkName: "component---src-pages-ai-design-agency-js" */),
  "component---src-pages-angularjs-development-js": () => import("./../../../src/pages/angularjs-development.js" /* webpackChunkName: "component---src-pages-angularjs-development-js" */),
  "component---src-pages-banking-design-agency-js": () => import("./../../../src/pages/banking-design-agency.js" /* webpackChunkName: "component---src-pages-banking-design-agency-js" */),
  "component---src-pages-branding-js": () => import("./../../../src/pages/branding.js" /* webpackChunkName: "component---src-pages-branding-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-training-workshop-js": () => import("./../../../src/pages/corporate-training-workshop.js" /* webpackChunkName: "component---src-pages-corporate-training-workshop-js" */),
  "component---src-pages-data-visualisation-js": () => import("./../../../src/pages/data-visualisation.js" /* webpackChunkName: "component---src-pages-data-visualisation-js" */),
  "component---src-pages-design-audit-js": () => import("./../../../src/pages/design-audit.js" /* webpackChunkName: "component---src-pages-design-audit-js" */),
  "component---src-pages-design-systems-js": () => import("./../../../src/pages/design-systems.js" /* webpackChunkName: "component---src-pages-design-systems-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../../../src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-e-governance-design-agency-js": () => import("./../../../src/pages/e-governance-design-agency.js" /* webpackChunkName: "component---src-pages-e-governance-design-agency-js" */),
  "component---src-pages-ecommerce-design-agency-js": () => import("./../../../src/pages/ecommerce-design-agency.js" /* webpackChunkName: "component---src-pages-ecommerce-design-agency-js" */),
  "component---src-pages-edtech-design-agency-js": () => import("./../../../src/pages/edtech-design-agency.js" /* webpackChunkName: "component---src-pages-edtech-design-agency-js" */),
  "component---src-pages-fintech-design-agency-js": () => import("./../../../src/pages/fintech-design-agency.js" /* webpackChunkName: "component---src-pages-fintech-design-agency-js" */),
  "component---src-pages-flutter-development-js": () => import("./../../../src/pages/flutter-development.js" /* webpackChunkName: "component---src-pages-flutter-development-js" */),
  "component---src-pages-gatsbyjs-development-js": () => import("./../../../src/pages/gatsbyjs-development.js" /* webpackChunkName: "component---src-pages-gatsbyjs-development-js" */),
  "component---src-pages-healthcare-design-agency-js": () => import("./../../../src/pages/healthcare-design-agency.js" /* webpackChunkName: "component---src-pages-healthcare-design-agency-js" */),
  "component---src-pages-hire-angularjs-developers-js": () => import("./../../../src/pages/hire-angularjs-developers.js" /* webpackChunkName: "component---src-pages-hire-angularjs-developers-js" */),
  "component---src-pages-hire-conversion-rate-optimisation-experts-js": () => import("./../../../src/pages/hire-conversion-rate-optimisation-experts.js" /* webpackChunkName: "component---src-pages-hire-conversion-rate-optimisation-experts-js" */),
  "component---src-pages-hire-gatsbyjs-developers-js": () => import("./../../../src/pages/hire-gatsbyjs-developers.js" /* webpackChunkName: "component---src-pages-hire-gatsbyjs-developers-js" */),
  "component---src-pages-hire-hotjar-experts-js": () => import("./../../../src/pages/hire-hotjar-experts.js" /* webpackChunkName: "component---src-pages-hire-hotjar-experts-js" */),
  "component---src-pages-hire-mobile-app-designers-js": () => import("./../../../src/pages/hire-mobile-app-designers.js" /* webpackChunkName: "component---src-pages-hire-mobile-app-designers-js" */),
  "component---src-pages-hire-nextjs-developers-js": () => import("./../../../src/pages/hire-nextjs-developers.js" /* webpackChunkName: "component---src-pages-hire-nextjs-developers-js" */),
  "component---src-pages-hire-nuxtjs-developers-js": () => import("./../../../src/pages/hire-nuxtjs-developers.js" /* webpackChunkName: "component---src-pages-hire-nuxtjs-developers-js" */),
  "component---src-pages-hire-reactjs-developers-js": () => import("./../../../src/pages/hire-reactjs-developers.js" /* webpackChunkName: "component---src-pages-hire-reactjs-developers-js" */),
  "component---src-pages-hire-saas-designers-js": () => import("./../../../src/pages/hire-saas-designers.js" /* webpackChunkName: "component---src-pages-hire-saas-designers-js" */),
  "component---src-pages-hire-ui-ux-designers-js": () => import("./../../../src/pages/hire-ui-ux-designers.js" /* webpackChunkName: "component---src-pages-hire-ui-ux-designers-js" */),
  "component---src-pages-hire-ux-researchers-js": () => import("./../../../src/pages/hire-ux-researchers.js" /* webpackChunkName: "component---src-pages-hire-ux-researchers-js" */),
  "component---src-pages-hire-vuejs-developers-js": () => import("./../../../src/pages/hire-vuejs-developers.js" /* webpackChunkName: "component---src-pages-hire-vuejs-developers-js" */),
  "component---src-pages-hire-web-designers-js": () => import("./../../../src/pages/hire-web-designers.js" /* webpackChunkName: "component---src-pages-hire-web-designers-js" */),
  "component---src-pages-hire-webflow-developers-js": () => import("./../../../src/pages/hire-webflow-developers.js" /* webpackChunkName: "component---src-pages-hire-webflow-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-design-agency-js": () => import("./../../../src/pages/iot-design-agency.js" /* webpackChunkName: "component---src-pages-iot-design-agency-js" */),
  "component---src-pages-logistics-design-agency-js": () => import("./../../../src/pages/logistics-design-agency.js" /* webpackChunkName: "component---src-pages-logistics-design-agency-js" */),
  "component---src-pages-mobile-app-design-agency-js": () => import("./../../../src/pages/mobile-app-design-agency.js" /* webpackChunkName: "component---src-pages-mobile-app-design-agency-js" */),
  "component---src-pages-mobile-app-development-company-js": () => import("./../../../src/pages/mobile-app-development-company.js" /* webpackChunkName: "component---src-pages-mobile-app-development-company-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-product-development-js": () => import("./../../../src/pages/product-development.js" /* webpackChunkName: "component---src-pages-product-development-js" */),
  "component---src-pages-project-alpha-test-js": () => import("./../../../src/pages/project/alpha-test.js" /* webpackChunkName: "component---src-pages-project-alpha-test-js" */),
  "component---src-pages-project-campaign-monitoring-platform-design-js": () => import("./../../../src/pages/project/campaign-monitoring-platform-design.js" /* webpackChunkName: "component---src-pages-project-campaign-monitoring-platform-design-js" */),
  "component---src-pages-project-customer-success-management-platform-design-js": () => import("./../../../src/pages/project/customer-success-management-platform-design.js" /* webpackChunkName: "component---src-pages-project-customer-success-management-platform-design-js" */),
  "component---src-pages-project-distribution-management-system-js": () => import("./../../../src/pages/project/distribution-management-system.js" /* webpackChunkName: "component---src-pages-project-distribution-management-system-js" */),
  "component---src-pages-project-ecommerce-web-design-cielo-js": () => import("./../../../src/pages/project/ecommerce-web-design-cielo.js" /* webpackChunkName: "component---src-pages-project-ecommerce-web-design-cielo-js" */),
  "component---src-pages-project-ecommerce-web-design-hoby-js": () => import("./../../../src/pages/project/ecommerce-web-design-hoby.js" /* webpackChunkName: "component---src-pages-project-ecommerce-web-design-hoby-js" */),
  "component---src-pages-project-edtech-mobile-app-quizzer-js": () => import("./../../../src/pages/project/edtech-mobile-app-quizzer.js" /* webpackChunkName: "component---src-pages-project-edtech-mobile-app-quizzer-js" */),
  "component---src-pages-project-elastic-sign-js": () => import("./../../../src/pages/project/elastic-sign.js" /* webpackChunkName: "component---src-pages-project-elastic-sign-js" */),
  "component---src-pages-project-email-marketing-app-inbox-pro-js": () => import("./../../../src/pages/project/email-marketing-app-inbox-pro.js" /* webpackChunkName: "component---src-pages-project-email-marketing-app-inbox-pro-js" */),
  "component---src-pages-project-employee-assessment-platform-design-js": () => import("./../../../src/pages/project/employee-assessment-platform-design.js" /* webpackChunkName: "component---src-pages-project-employee-assessment-platform-design-js" */),
  "component---src-pages-project-fio-js": () => import("./../../../src/pages/project/fio.js" /* webpackChunkName: "component---src-pages-project-fio-js" */),
  "component---src-pages-project-gizmo-js": () => import("./../../../src/pages/project/gizmo.js" /* webpackChunkName: "component---src-pages-project-gizmo-js" */),
  "component---src-pages-project-hushly-js": () => import("./../../../src/pages/project/hushly.js" /* webpackChunkName: "component---src-pages-project-hushly-js" */),
  "component---src-pages-project-icegram-js": () => import("./../../../src/pages/project/icegram.js" /* webpackChunkName: "component---src-pages-project-icegram-js" */),
  "component---src-pages-project-igaming-js": () => import("./../../../src/pages/project/igaming.js" /* webpackChunkName: "component---src-pages-project-igaming-js" */),
  "component---src-pages-project-kantime-js": () => import("./../../../src/pages/project/kantime.js" /* webpackChunkName: "component---src-pages-project-kantime-js" */),
  "component---src-pages-project-kantime-lms-js": () => import("./../../../src/pages/project/kantime-lms.js" /* webpackChunkName: "component---src-pages-project-kantime-lms-js" */),
  "component---src-pages-project-kyss-js": () => import("./../../../src/pages/project/kyss.js" /* webpackChunkName: "component---src-pages-project-kyss-js" */),
  "component---src-pages-project-landscape-pro-js": () => import("./../../../src/pages/project/landscape-pro.js" /* webpackChunkName: "component---src-pages-project-landscape-pro-js" */),
  "component---src-pages-project-logistics-insight-app-design-js": () => import("./../../../src/pages/project/logistics-insight-app-design.js" /* webpackChunkName: "component---src-pages-project-logistics-insight-app-design-js" */),
  "component---src-pages-project-logistics-website-designing-js": () => import("./../../../src/pages/project/logistics-website-designing.js" /* webpackChunkName: "component---src-pages-project-logistics-website-designing-js" */),
  "component---src-pages-project-logo-yazilim-js": () => import("./../../../src/pages/project/logo-yazilim.js" /* webpackChunkName: "component---src-pages-project-logo-yazilim-js" */),
  "component---src-pages-project-mobile-dating-app-heartsy-js": () => import("./../../../src/pages/project/mobile-dating-app-heartsy.js" /* webpackChunkName: "component---src-pages-project-mobile-dating-app-heartsy-js" */),
  "component---src-pages-project-nirvana-js": () => import("./../../../src/pages/project/nirvana.js" /* webpackChunkName: "component---src-pages-project-nirvana-js" */),
  "component---src-pages-project-rentcity-js": () => import("./../../../src/pages/project/rentcity.js" /* webpackChunkName: "component---src-pages-project-rentcity-js" */),
  "component---src-pages-project-s-2-c-help-manual-js": () => import("./../../../src/pages/project/s2c-help-manual.js" /* webpackChunkName: "component---src-pages-project-s-2-c-help-manual-js" */),
  "component---src-pages-project-sangti-js": () => import("./../../../src/pages/project/sangti.js" /* webpackChunkName: "component---src-pages-project-sangti-js" */),
  "component---src-pages-project-shipdelight-js": () => import("./../../../src/pages/project/shipdelight.js" /* webpackChunkName: "component---src-pages-project-shipdelight-js" */),
  "component---src-pages-project-stride-js": () => import("./../../../src/pages/project/stride.js" /* webpackChunkName: "component---src-pages-project-stride-js" */),
  "component---src-pages-project-survey-2-connect-js": () => import("./../../../src/pages/project/survey2connect.js" /* webpackChunkName: "component---src-pages-project-survey-2-connect-js" */),
  "component---src-pages-project-trackier-js": () => import("./../../../src/pages/project/trackier.js" /* webpackChunkName: "component---src-pages-project-trackier-js" */),
  "component---src-pages-project-ui-ux-auto-service-booking-js": () => import("./../../../src/pages/project/ui-ux-auto-service-booking.js" /* webpackChunkName: "component---src-pages-project-ui-ux-auto-service-booking-js" */),
  "component---src-pages-project-ui-ux-design-align-plus-js": () => import("./../../../src/pages/project/ui-ux-design-align-plus.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-align-plus-js" */),
  "component---src-pages-project-ui-ux-design-auto-recruits-js": () => import("./../../../src/pages/project/ui-ux-design-auto-recruits.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-auto-recruits-js" */),
  "component---src-pages-project-ui-ux-design-baa-platform-js": () => import("./../../../src/pages/project/ui-ux-design-baa-platform.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-baa-platform-js" */),
  "component---src-pages-project-ui-ux-design-boardmeetingz-js": () => import("./../../../src/pages/project/ui-ux-design-boardmeetingz.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-boardmeetingz-js" */),
  "component---src-pages-project-ui-ux-design-cloudforcehr-js": () => import("./../../../src/pages/project/ui-ux-design-cloudforcehr.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-cloudforcehr-js" */),
  "component---src-pages-project-ui-ux-design-developly-js": () => import("./../../../src/pages/project/ui-ux-design-developly.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-developly-js" */),
  "component---src-pages-project-ui-ux-design-energy-trade-js": () => import("./../../../src/pages/project/ui-ux-design-energy-trade.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-energy-trade-js" */),
  "component---src-pages-project-ui-ux-design-halagig-js": () => import("./../../../src/pages/project/ui-ux-design-halagig.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-halagig-js" */),
  "component---src-pages-project-ui-ux-design-kesari-tap-js": () => import("./../../../src/pages/project/ui-ux-design-kesari-tap.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-kesari-tap-js" */),
  "component---src-pages-project-ui-ux-design-kys-js": () => import("./../../../src/pages/project/ui-ux-design-kys.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-kys-js" */),
  "component---src-pages-project-ui-ux-design-likehire-js": () => import("./../../../src/pages/project/ui-ux-design-likehire.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-likehire-js" */),
  "component---src-pages-project-ui-ux-design-moms-belief-js": () => import("./../../../src/pages/project/ui-ux-design-moms-belief.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-moms-belief-js" */),
  "component---src-pages-project-ui-ux-design-payinvoice-js": () => import("./../../../src/pages/project/ui-ux-design-payinvoice.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-payinvoice-js" */),
  "component---src-pages-project-ui-ux-design-pos-forex-js": () => import("./../../../src/pages/project/ui-ux-design-pos-forex.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-pos-forex-js" */),
  "component---src-pages-project-ui-ux-design-style-spot-js": () => import("./../../../src/pages/project/ui-ux-design-style-spot.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-style-spot-js" */),
  "component---src-pages-project-ui-ux-design-sunday-dealz-js": () => import("./../../../src/pages/project/ui-ux-design-sunday-dealz.js" /* webpackChunkName: "component---src-pages-project-ui-ux-design-sunday-dealz-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-rapid-prototyping-js": () => import("./../../../src/pages/rapid-prototyping.js" /* webpackChunkName: "component---src-pages-rapid-prototyping-js" */),
  "component---src-pages-reactjs-development-js": () => import("./../../../src/pages/reactjs-development.js" /* webpackChunkName: "component---src-pages-reactjs-development-js" */),
  "component---src-pages-saas-design-agency-js": () => import("./../../../src/pages/saas-design-agency.js" /* webpackChunkName: "component---src-pages-saas-design-agency-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shopify-development-js": () => import("./../../../src/pages/shopify-development.js" /* webpackChunkName: "component---src-pages-shopify-development-js" */),
  "component---src-pages-telecom-design-agency-js": () => import("./../../../src/pages/telecom-design-agency.js" /* webpackChunkName: "component---src-pages-telecom-design-agency-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-ui-development-js": () => import("./../../../src/pages/ui-development.js" /* webpackChunkName: "component---src-pages-ui-development-js" */),
  "component---src-pages-ui-ux-agency-ahmedabad-js": () => import("./../../../src/pages/ui-ux-agency-ahmedabad.js" /* webpackChunkName: "component---src-pages-ui-ux-agency-ahmedabad-js" */),
  "component---src-pages-ui-ux-agency-bangalore-js": () => import("./../../../src/pages/ui-ux-agency-bangalore.js" /* webpackChunkName: "component---src-pages-ui-ux-agency-bangalore-js" */),
  "component---src-pages-ui-ux-agency-gurgaon-js": () => import("./../../../src/pages/ui-ux-agency-gurgaon.js" /* webpackChunkName: "component---src-pages-ui-ux-agency-gurgaon-js" */),
  "component---src-pages-ui-ux-agency-mumbai-js": () => import("./../../../src/pages/ui-ux-agency-mumbai.js" /* webpackChunkName: "component---src-pages-ui-ux-agency-mumbai-js" */),
  "component---src-pages-ui-ux-agency-surat-js": () => import("./../../../src/pages/ui-ux-agency-surat.js" /* webpackChunkName: "component---src-pages-ui-ux-agency-surat-js" */),
  "component---src-pages-ui-ux-design-agency-chicago-js": () => import("./../../../src/pages/ui-ux-design-agency-chicago.js" /* webpackChunkName: "component---src-pages-ui-ux-design-agency-chicago-js" */),
  "component---src-pages-ui-ux-design-agency-dubai-js": () => import("./../../../src/pages/ui-ux-design-agency-dubai.js" /* webpackChunkName: "component---src-pages-ui-ux-design-agency-dubai-js" */),
  "component---src-pages-ui-ux-design-agency-india-js": () => import("./../../../src/pages/ui-ux-design-agency-india.js" /* webpackChunkName: "component---src-pages-ui-ux-design-agency-india-js" */),
  "component---src-pages-ui-ux-design-company-india-js": () => import("./../../../src/pages/ui-ux-design-company-india.js" /* webpackChunkName: "component---src-pages-ui-ux-design-company-india-js" */),
  "component---src-pages-ui-ux-design-company-usa-js": () => import("./../../../src/pages/ui-ux-design-company-usa.js" /* webpackChunkName: "component---src-pages-ui-ux-design-company-usa-js" */),
  "component---src-pages-ui-ux-designing-js": () => import("./../../../src/pages/ui-ux-designing.js" /* webpackChunkName: "component---src-pages-ui-ux-designing-js" */),
  "component---src-pages-usability-testing-js": () => import("./../../../src/pages/usability-testing.js" /* webpackChunkName: "component---src-pages-usability-testing-js" */),
  "component---src-pages-user-acquisition-consulting-js": () => import("./../../../src/pages/user-acquisition-consulting.js" /* webpackChunkName: "component---src-pages-user-acquisition-consulting-js" */),
  "component---src-pages-user-research-js": () => import("./../../../src/pages/user-research.js" /* webpackChunkName: "component---src-pages-user-research-js" */),
  "component---src-pages-ux-consulting-js": () => import("./../../../src/pages/ux-consulting.js" /* webpackChunkName: "component---src-pages-ux-consulting-js" */),
  "component---src-pages-vuejs-development-js": () => import("./../../../src/pages/vuejs-development.js" /* webpackChunkName: "component---src-pages-vuejs-development-js" */),
  "component---src-pages-web-development-company-js": () => import("./../../../src/pages/web-development-company.js" /* webpackChunkName: "component---src-pages-web-development-company-js" */),
  "component---src-pages-webflow-development-js": () => import("./../../../src/pages/webflow-development.js" /* webpackChunkName: "component---src-pages-webflow-development-js" */),
  "component---src-pages-website-design-agency-js": () => import("./../../../src/pages/website-design-agency.js" /* webpackChunkName: "component---src-pages-website-design-agency-js" */),
  "component---src-pages-wordpress-development-js": () => import("./../../../src/pages/wordpress-development.js" /* webpackChunkName: "component---src-pages-wordpress-development-js" */)
}

